<template>
  <div style="width: 100%; text-align: center; margin-top: 30px">
    {{ serverUrl }}
    <br />
    {{ loginInfo }}
  </div>
</template>

<script>
import zhCN from "vant/es/locale/lang/zh-CN";
import zhTW from "vant/es/locale/lang/zh-TW";
import enUS from "vant/es/locale/lang/en-US";
import { setSessionStr, setSessionObj } from "@/utils/session.js";

export default {
  data() {
    return {
      loginInfo: this.$t("lang.empty"),
      serverUrl: "",
    };
  },
  created() {
    localStorage.setItem("client", "app");

    let lang = this.$route.query.I18N;
    localStorage.setItem("locale", lang);

    this.$i18n.locale = lang;

    if (lang == "EN") this.$Locale.use("en", enUS);
    else if (lang == "TC") this.$Locale.use("zhTW", zhTW);
    else this.$Locale.use("zhCN", zhCN);

    document.title = this.$t("lang.apptitle");
  },
  mounted() {
    try {
      window.serviceDomainCallback = (e) => {
        this.infoCallback1(e);
      };
      this.loginInfo = this.$t("lang.loading");
      window.getServiceDomain.postMessage("");
    } catch (e) {
      this.serverUrl = e;
    }

    try {
      window.infoCallback = (e) => {
        this.infoCallback(e);
      };
      this.loginInfo = this.$t("lang.loading");
      window.appUserInfo.postMessage("");
    } catch (e) {
      this.loginInfo = e;
    }
  },
  methods: {
    infoCallback1(serverurl) {
      if (serverurl.charAt(serverurl.length - 1) != "/")
        serverurl = serverurl + "/";
      setSessionStr("ServerUrl", serverurl);
    },

    infoCallback(strUserInfo) {
      let _strUserInfo = "";
      try {
        _strUserInfo = strUserInfo.replace("\n","＼n").replace("\r","＼r").replace("\t","＼t").replace("\b","＼b").replace("\f","＼f").replace("\v","＼v"); // .replace(/\\/g, "＼")
        let userinfo = JSON.parse(_strUserInfo).data;
        this.$store.commit("setToken", userinfo.token);
        this.$store.commit("setUser", userinfo.user);
        this.$store.commit("setCompanys", userinfo.companys);
        this.$store.commit("setDepts", this.$Tools.sortBykey(userinfo.depts, "id"));
        this.$store.commit("setGroup", userinfo.group);
        this.$store.commit("setInspector", userinfo.inspector);
        this.$store.commit("setPermission", userinfo.permission);
        this.$store.commit("setPlace", userinfo.place);
        this.$store.commit("setRoutes", this.$Tools.sortBykey(userinfo.routes, "id"));
        this.$store.commit("setDevices", userinfo.devices == undefined ? [] : userinfo.devices);

        //初始化查询条件
        let date1 = this.$Tools.FormatDate2(new Date()) + " 00:00:01";
        let date2 = this.$Tools.FormatDate2(new Date()) + " 23:59:59";
        setSessionStr("Query_Date", this.$t("lang.today"));
        setSessionStr("Query_Date_1", date1);
        setSessionStr("Query_Date_2", date2);

        let com_name =
          userinfo.companys.length > 0 ? userinfo.companys[0].name : "";
        let com_id = userinfo.companys.length > 0 ? userinfo.companys[0].id : 0;
        setSessionStr("Query_Company", com_name);
        setSessionStr("Query_Company_id", com_id);

        let dept_name = "";
        let dept_id = 0;
        if (userinfo.depts.length > 0) {
          let lstData = userinfo.depts.filter((d) => {
            return d.companyId == com_id;
          });
          dept_name = lstData.length > 0 ? lstData[0].name : "";
          dept_id = lstData.length > 0 ? lstData[0].id : 0;
        }
        setSessionStr("Query_Dept", dept_name);
        setSessionStr("Query_Dept_id", dept_id);
        setSessionStr("Query_RepairType", "-1");
        setSessionStr("Query_Record_miss", "0");
        setSessionStr("Query_Record_local", "1");

        //参数
        let par = {
          mCompany: com_id,
          mDept: dept_id,
          mDate1: date1,
          mDate2: date2,
          mLine: "",
          mAddr: "",
          mGroup: "",
          mUser: "",
          mDevice: "",
          mRecord: "-1",
          mRecord_miss: "0",
          mRecord_local: "1",
          mState: "",
          mOrder: "desc",
          mTotalType: "4",
          mRepairType: "-1",
          mKeyword: "",
          pagesize: 20,
        };
        setSessionObj("Query_Params", par);

        this.$store.commit("setIsXunjianApp", false);
        this.$router.replace({ path: "/query/index" });

      } catch (e) {
        this.loginInfo = "程序错误：" + e + _strUserInfo;
      }
    },
  },
};
</script>
